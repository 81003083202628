import { React, useState, useEffect, useContext } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Row, Col, Tooltip } from 'antd';
import Box from '@iso/components/utility/box';
import LayoutWrapper from '@iso/components/utility/layoutWrapper';
import { SelectOption } from '@iso/components/uielements/select';
import { useLocation, useNavigate } from 'react-router-dom';
import ContentHolder from '@iso/components/utility/contentHolder';
import parse from "html-react-parser";
import api from '../../../api';
import { AppContext } from '../../../context/ContextProvider';
import { AlertContext } from '../../../context/alertContext';
import customScriptIcon from '@iso/assets/images/custom-script-icon.svg';
import blueTriangleWorkflow from '@iso/assets/images/blueTriangleWorkflow.svg';
import dragIcon from '@iso/assets/images/drag-icon.svg';
import awsLogo from '../../../assets/images/aws.svg';
import { marketplaceStatuses, timeSince } from '../../../context/helper';
import CreateTaskHeader from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskHeader/CreateTaskHeader';
import { StickerWidgetWrapper } from '../../Widgets/SmallCards/SmallCards.styles';
import plusIcon from '@iso/assets/images/plusIcon.svg';
import ScrollableFeed from 'react-scrollable-feed';
import CodeViewGeneral from '../../Clusters/Task/CodeViewGeneral';
import CreateTaskFooter from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskFooter/CreateTaskFooter';


let innerTitleStyle = {
    color: "#1f1f1f",
    fontFamily: "Poppins",
    fontWeight: "500",
    fontSize: "12px"
};

let innerSubtitleStyle = {
    fontSize: "11px",
    color: "#9B9B9B",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
}

const blueTriangleStyle = {
    position: "absolute",
    right: "2.5%",
    top: "40%",
}

const widgetStyle = {
    display: "block",
    margin: "40px 20px",
    width: "inherit",
};

export default ({
    data,
    openDrawer,
    columnId,
}) => {

    const { openRightDrawer, setOpenRightDrawer, workflowData, setWorkflowData, currentWorkflowData, setCurrentWorkflowData } = useContext(AppContext);
    const { alertOptions, set_alertOptions } = useContext(AlertContext);

    const navigate = useNavigate();
    const location = useLocation();

    const [script, setScript] = useState("");
    const [error, setError] = useState(false);

    useEffect(() => {
        console.log("inside customScript raw :", currentWorkflowData.sourceConfig.customScriptConfig.name);
        if(currentWorkflowData?.sourceConfig?.customScriptConfig?.name){
            let str = currentWorkflowData.sourceConfig.customScriptConfig.config.script;
            try {
                let newStr = JSON.parse(str);
                if(newStr.startsWith('\"') && newStr.endsWith('\"')){
                    newStr = newStr.replace(/\\n/g, "\n").replace(/\\"/g, '"');
                    newStr = newStr.slice(1, -1);
                }
                console.log("New parsed script :", newStr);
                setScript(newStr);
            } catch (err) {
                console.log("err :", err);
                console.log("using script as is...");
                setScript(currentWorkflowData.sourceConfig.customScriptConfig.config.script);
            }
        }
    }, []);

    const handelClick = () => {
        setOpenRightDrawer({
            drawerType: false,
            drawerProps: {
                data: "",
            },
        });
    }
    
    const handleSaveDetails = (e) => {
        console.log("clicked handleSaveDetails and script is :", script);
        if(!script){
            setError(true);
            return;
        }

        setWorkflowData((prevData) => ({
            ...prevData,
            // customScript: script,
        }));

        let obj = {
            name: "pre_build_script",
            enabled: true,
            config: {
                agent: "ubuntu:20.04",
                script: script
                // script: "#!/bin/bash\r\n\r\n ls -la;# Count the number of environment variables\r\ncount=$(printenv | wc -l)\r\n\r\n# Check if the count is greater than 10\r\nif [ \"$count\" -gt 10 ]; then\r\n    echo \"More than 10 variables are located\" \r\nelse\r\n    echo \"10 or fewer variables are located\"\r\nfi\r\n"
            }
        }

        if(script === ""){
            obj = {}
        }

        currentWorkflowData.sourceConfig.customScriptConfig = obj;
        setCurrentWorkflowData({...currentWorkflowData});
        localStorage.setItem("Workflow", JSON.stringify({ ...currentWorkflowData }));
        handelClick();
    }
    
    const handleCancel = (e) => {
        console.log("clicked handleCancel...");
        handelClick();
    }

    const handleDragTools = (e) => {
        console.log("clicked handleDragTools :", e.target);
        console.log("clicked handleDragTools e.target.id :", e.target.id);

        if (e.target?.id) {
            // setToolSelected(parseInt(e.target.id));
            // setAllTools([...allTools]);
        }
    }



    return (
        <LayoutWrapper className="clusterInfo" style={{ padding: "0", height: "100%" }}>
            <CreateTaskHeader buttonContent={columnId} onCancel={handelClick} />

            <div style={{ marginTop: "84px", width: "100%" }} />

            <Col lg={8} md={8} sm={8} xs={8} style={{ borderRight: "1px solid #ebebeb", height: "inherit" }}>
                <Box
                    title="Configure tools for pipeline"
                    subtitle="Drag and drop to add tools in the flow"
                    titleStyle={{...innerTitleStyle, lineHeight: "20px"}}
                    subTitleStyle={innerSubtitleStyle}
                    style={{ border: 0, padding: "0px 20px", margin: 0, marginTop: "40px", height: "fit-content" }}
                />
                
                <div style={{ position: "relative" }}>
                    <StickerWidgetWrapper className="isoStickerWidget" style={{ marginTop: "16px", marginLeft: "22px", width: "250px", position: "relative" }}>
                        <Box style={{ borderRadius: "10px", cursor: "pointer", padding: "0", position: "relative" }}>
                            <ContentHolder
                                style={{ display: "flex", alignItems: "start", cursor: "pointer", marginTop: "0", position: "relative", padding: "18px" }}
                            >
                                <Box
                                    title={
                                        <div style={{display:"flex", justifyContent: "start", alignItems: "center", position: "relative", paddingRight: "55px"}}>
                                            <span style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "100%", width: "36px", height: "36px", background: "white", padding: "3px", boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.25)" }}>
                                                <img src={customScriptIcon} />
                                            </span>
                                            <div>
                                                <p style={{ color: "#1F1F1F", marginLeft: "10px", fontSize: "12px", lineHeight: "20px", fontFamily: "Poppins" }}>Custom Script</p>
                                                <p style={{ marginLeft: "10px", ...innerSubtitleStyle }}>Click to configuration</p>
                                            </div>
                                        </div>
                                    }
                                    // subtitle={<p style={{ lineHeight: "24px", color: "#9B9B9B" }}>Click to configuration</p>}
                                    style={{ border: "transparent", padding: "0", marginBottom: "0", position: "relative" }}
                                    titleStyle={{ ...innerTitleStyle, marginBottom: "0", position: "relative" }}
                                    subTitleStyle={innerSubtitleStyle}
                                />
                            </ContentHolder>
                        </Box>
                    </StickerWidgetWrapper>
                    {/* <span style={blueTriangleStyle} /> */}
                    <img src={blueTriangleWorkflow} style={blueTriangleStyle} />
                    <img src={dragIcon} alt="drag" onClick={handleDragTools} style={{ position: "absolute", top: "29px", left: "13px", width: "18px", height: "22px", objectFit: "cover", cursor: "pointer" }} />
                </div>
            </Col>

            <Col lg={16} md={16} sm={16} xs={16} style={{ marginBottom: "100px" }}>
                <StickerWidgetWrapper className="isoStickerWidget" style={{ ...widgetStyle }}>
                    <CodeViewGeneral data={script} setData={setScript} setError={setError} height="calc(100vh - 300px)" minHeight="370px" type="script" />
                    {error && <p className="isoHelperText labels">Pre-Build script can't be empty</p>}
                </StickerWidgetWrapper>
            </Col>

            <CreateTaskFooter buttonContent={"Custom Script"} givenButtonFunction1={handleSaveDetails} givenButtonFunction2={handleCancel} openDrawer={openDrawer} />
            
        </LayoutWrapper>
    );
};