import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import Switch from '@iso/components/uielements/switch';
import Select, { SelectOption } from '@iso/components/uielements/select';
import Form from '@iso/components/uielements/form';
import PageHeader from '@iso/components/utility/pageHeader';
import Box from '@iso/components/utility/box';
import LayoutWrapper from '@iso/components/utility/layoutWrapper';
import ContentHolder from '@iso/components/utility/contentHolder';
import basicStyle from '@iso/assets/styles/constants';
import { switchOptions, selectOptions, defaultValues } from '../../AdvancedUI/CodeMirror/config';
import CodeMirror from '@uiw/react-codemirror';
import { StreamLanguage } from "@codemirror/language";
import { shell } from "@codemirror/legacy-modes/mode/shell";
import { yaml } from "@codemirror/legacy-modes/mode/yaml";
import CreateTaskHeader from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskHeader/CreateTaskHeader';
import { useContext } from 'react';
import { AppContext } from '../../../context/ContextProvider';
// import { javascript } from '@codemirror/lang-javascript';
import { androidstudio, androidstudioInit } from '@uiw/codemirror-theme-androidstudio';
// import { tags as t } from '@lezer/highlight';

const FormItem = Form.Item;
const Option = SelectOption;

let code = `%TAG ! tag:clarkevans.com,2002:
--- !shape
  # Use the ! handle for presenting
  # tag:clarkevans.com,2002:circle
- !circle
  center: &ORIGIN {x: 73, y: 129}
  radius: 7
- !line
  start: *ORIGIN
  finish: { x: 89, y: 102 }
- !label
  start: *ORIGIN
  color: 0xFFEEBB
  text: Pretty vector drawing.`;

export default function({
  data,
  height,
  minHeight,
  type,
  setData,
  setError,
  values,
  submitCount,
  onCancel,
  onDelete,
  onEditCancel,
  tabSelected,
}) {
  // const { rowStyle, colStyle, gutter } = basicStyle;
  const {openRightDrawer, setOpenRightDrawer, allClusters, setAllClusters, marketplaceData, setMarketplaceData } = useContext(AppContext);
  const [newYAMLCode, setNewYAMLCode] = useState(data);

  useEffect(() => {
    if(marketplaceData.overviewData){
      marketplaceData.overviewData.yaml.updatedContent = newYAMLCode;
      setMarketplaceData({...marketplaceData});
    }
  }, [newYAMLCode]);
  
  useEffect(() => {
    if(data != newYAMLCode){
      console.log("data :", data);
      console.log("newYAMLCode :", newYAMLCode);
      setNewYAMLCode(data);
    }
  }, [data]);
  

  return (
    <LayoutWrapper style={{ padding: type==="script" ? "0" : "40px 20px" }}>
        <Box style={{padding: 0}}>
          <ContentHolder style={{marginTop: "0"}}>
            <CodeMirror
              value={newYAMLCode}
              height={height}
              minHeight={minHeight ? minHeight : ""}
              theme={androidstudio}
              // extensions={[javascript({ jsx: true })]}
              extensions={type === "script" ? [StreamLanguage.define(shell)] : [StreamLanguage.define(yaml)]}
              // options={{
              //   theme: 'androidstudio',
              //   keyMap: 'sublime',
              //   mode: 'text/x-yaml',
              // }}
              onChange={(value)=>{
                if(type === "script"){
                  setData(value);
                  console.log("value in CodeMirror :", value);
                  setError(false);
                }
                else{
                  setNewYAMLCode(value);
                }
              }}
            />
          </ContentHolder>
        </Box>
    </LayoutWrapper>
  );
}
